import { Children, lazy } from 'react';
import { LandingProvider } from 'src/contexts/LandingContext';
import { element } from 'prop-types';

export const landingRoute = (loader: any) => {
    const LandingPage = loader(lazy(() => import('src/layouts/LandingLayout')));

    const LandingIndex = loader(lazy(() => import('src/pages/landing/Index')))
    const Client = loader(lazy(() => import('src/pages/landing/Clients')))
    const ClientDetails = loader(lazy(() => import('src/pages/landing/ClientDetails')))
    const Article = loader(lazy(() => import('src/pages/landing/Article')))

    return [
        {
            path: '/',
            children: [
                { path: '', element: (<LandingIndex />) },
                { path: 'clients', element: (<Client />) },
                { path: 'clients/detail', element: (<ClientDetails />) },
                { path: 'article', element: (<Article />) },
            ],
            element: (
                <>
                    <LandingProvider>
                        <LandingPage />
                    </LandingProvider>
                </>
            ),


        },

    ];
};
