import React, { useState, createContext, useContext, ReactNode, FC, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

type MenuItem = {
  label: string;
  anchorId: string;
  isAnchor: boolean
};

type LandingContextProps = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  theme: any;
  isMobile: boolean;
  menuItems: MenuItem[];
  scrolled: boolean;
  aboutUsTabButton: any;
  aboutUsTabValue: any;
  setAboutUsTabValue: any,
  product: any;
  setProduct: any;

};

const LandingContext = createContext<LandingContextProps | undefined>(
  undefined
);

export const useLanding = (): LandingContextProps => {
  const context = useContext(LandingContext);
  if (!context) {
    throw new Error('useLanding must be used within a LandingProvider');
  }
  return context;
};

type LandingProviderProps = {
  children: ReactNode;
};

export const LandingProvider: FC<LandingProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const aboutUsTabButton = ['Our Value', 'Our Vision', 'Our Mission']
  const [aboutUsTabValue, setAboutUsTabValue] = useState<string>(aboutUsTabButton[0])

  const toggleSidebar = () => setSidebarToggle((prev) => !prev);
  const closeSidebar = () => setSidebarToggle(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems: MenuItem[] = [
    { label: 'Home', isAnchor: true, anchorId: 'home' },
    { label: 'Service', isAnchor: true, anchorId: 'service' },
    { label: 'Our Client', isAnchor: false, anchorId: 'clients' },
    { label: 'Article', isAnchor: false, anchorId: 'article' },
    { label: 'Contact', isAnchor: true, anchorId: 'contact' },
    ,

  ];

  const [product, setProduct] = useState(null)

  React.useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    console.log(product);

  }, [product])

  return (
    <LandingContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        theme,
        isMobile,
        menuItems,
        scrolled,
        aboutUsTabButton,
        aboutUsTabValue,
        setAboutUsTabValue,
        product,
        setProduct
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};
